<script>
// import GameCard from "@/components/Card.vue";

export default {
    name: "",
    // components: {GameCard},
    data() {
        return {
            form: null,
            show: false,
            modal: [],
            selectList: [],
            selectListI: [],
            loading: true,
            list: [],
            listItem: [],
            type: 0, // 1 mago推荐 2 老虎机 3 真人娱乐场 4 小游戏  5新游戏 6捕鱼 7桌面游戏 8JDB
            other: null, // 1 历史游戏记录 2我的收藏
        }
    },
    // created() {
    // this.type = this.$route.query.gameCategory
    // this.other = this.$route.query.other
    // if (this.other == 1) {
    //     this.getHistory()
    // } else if (this.other == 2) {
    //     this.getHistory()
    // } else {
    //     this.getSlotGameList()
    // }
    // },
    mounted() {
        // 监听点击事件
        document.addEventListener("click", this.handleOutsideClick);
    },
    watch: {
        modal(newVal, old) {
            if (newVal.length) {
                this.list = this.dealList(this.listItem, 2)
            } else {
                this.list = this.dealList(this.listItem, 3)
            }
        },

    },
    methods: {
        // 1 mago推荐 2 老虎机 3 真人娱乐场 4 小游戏  5新游戏 6捕鱼 7桌面游戏
        getSlotGameList() {
            // let param = {};
            // param.gameCategory = Number(this.type) //gamecategory 必填，且不能<1
            // param.pageNum = 1
            // param.pageSize = 9999
            // this.$http
            //     .post(this.host + this.api.uc.gameList, param)
            //     .then(response => {
            //         let resp = response.body
            //         if (resp.code == '0000') {
            //             this.list = this.dealList(resp.data.list)
            //             this.loading = false
            //             this.listItem = JSON.parse(JSON.stringify(resp.data.list))
            //         } else {
            //             this.$Notice.error({
            //                 title: this.$t("common.tip"),
            //                 desc: resp.mesg
            //             });
            //             this.realHeight = '50rem'
            //         }
            //     });

            let params = {
                gameType: '',
                ccy: 'USDT'
            }
            this.$http.get(this.host + '/fb-live/getLiveTable', {params}).then(response => {
                let resp = response.body
                console.log(resp)
                // if (resp.code == '0000') {
                // } else {
                // }
            });

            let data = {
                ccy: "USDT"
            }
            this.$http.post(this.host + '/fb-live/getFbLiveGameUrl', data).then(response => {
                let resp = response.body
                console.log(resp)
                // if (resp.code == '0000') {
                // } else {
                // }
                this.$router.push({path: '/gamepage1', query: {name: '真人视讯', fcUrl: resp['redirect-url']}})
                // window.open(resp['redirect-url'])
            });
        },
        getHistory() { // 1 历史游戏记录 2我的收藏
            let ids, user
            user = JSON.parse(localStorage.getItem("MEMBER"))
            ids = this.other == 1 ? user.lastLoginDevice : user.registerDevice
            let params = {ids}
            this.$http.get(this.host + '/game/getGameByIds', {params}).then(response => {
                let resp = response.body;
                if (resp.code == '0000') {
                    this.list = this.dealList(resp.data.list)
                    this.loading = false
                } else {
                    this.$Message.error(resp.mesg);
                }
            });
        },
        dealList(list, type) {  //type2 选择游戏厂商
            let newList = []
            if (list && list.length) {
                list.map((item, i) => {
                    if (this.selectListI.indexOf(item.gameFactory) < 0) {
                        if (type !== 2 && type !== 3) {
                            this.selectListI.push(item.gameFactory)
                        }
                    }
                    // 处理特殊情况，如 The Catfather 需要特殊的图片文件名
                    if (item.gameName.toLowerCase() === 'the catfather') {
                        // 特定文件名的处理
                        item.path = require('@/assets/gameImg/' + 'The Catfather Part II.webp');
                    } else {
                        // 默认情况下根据 gameName 动态加载图片
                        try {
                            // 使用 require 动态加载图片路径，注意 .webp 结尾
                            item.path = require(`@/assets/gameImg/${item.gameName}.webp`);
                        } catch (e) {
                            try {  //第一次匹配失败 转为小写
                                // console.log('--------------------')
                                item.path = require(`@/assets/gameImg/${item.gameName.toLowerCase()}.webp`)
                            } catch (err) {
                                // console.error(`无法加载图片: ${item.gameName}.webp`);
                                // 如果图片不存在，设置默认图片
                                item.path = require('@/assets/gameImg/default.png');
                            }
                        }
                    }
                    // debugger
                    if (type === 2) {
                        if (this.modal.indexOf(item.gameFactory) >= 0) newList.push(item)
                    }
                });
            }
            if (type !== 2 && type !== 3) this.selectListI.forEach(item => {
                this.selectList.push({
                    value: item,
                    label: item,
                    check: false,
                })
            })
            return type === 2 ? newList : list
        },
        handleOutsideClick(event) {
            // 如果点击的是空白处（不是下拉菜单）
            console.log(this.$refs.dropdownContainer.contains(event.target))
            if (!this.$refs.dropdownContainer.contains(event.target)) {
                this.show = false;
            }
        }
    },
    beforeDestroy() {
        // 组件销毁时移除事件监听
        document.removeEventListener("click", this.handleOutsideClick);
    },
}
</script>

<template>
    <div class="gameAll">
        <div style="padding: 10px" @click="getSlotGameList">视讯测试按钮</div>
    </div>
</template>

<style scoped lang="scss">
.gameAll {
    width: 100%;
    //height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;

    .loading {
        margin: auto;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .top {
        background-color: #213743;
        display: flex;
        justify-content: space-around;
        height: 80px;

        .l {
            width: 30%;
            line-height: 80px;
            font-size: 20px;
            color: #fff;
            font-weight: 800;
        }

        .r {
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: end;

            & > img {
                height: 100%;
            }
        }
    }

    .select {
        width: 40%;
        height: 40px;
        margin: 20px 10px;

        .select-con {
            min-width: 124px;
            height: 40px;
            font-weight: bold;
            background-color: #0E212E;
            position: relative;

            .t {
                width: 100%;
                height: 100%;
                position: absolute;
                line-height: 40px;

                .num {
                    background-color: #4491E6;
                    color: #000;
                    padding: 4px 10px;
                    border-radius: 16px;
                }
            }

            .sel {
                border-radius: 6px;
                padding: 10px 0;
                top: 100%;
                width: 140%;
                position: absolute;
                background-color: #fff;
                display: flex;
                flex-direction: column;

                .sel-item {
                    width: 80%;
                    margin: auto;
                    display: flex;
                    justify-content: start;
                    align-items: center;

                    input {
                        margin: 5px;
                        width: 24px;
                        height: 24px;
                    }


                }

                .check {
                    width: 24px;
                    height: 24px;
                    margin: 5px;
                }

                .txt {
                    color: #000;
                }
            }
        }

    }

    .card-box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        & > div {
            width: 33.33%;
        }
    }
}
</style>
